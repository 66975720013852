import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Button,
  notification,
  Switch,
  Skeleton,
} from 'antd';

import useBreadcrumb from '../../../core/hooks/useBreadcrumb';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { useEffect, useRef, useState } from 'react';
import { Authentication } from '../../../auth/Auth';
import { updateUser } from '../../../core/store/Auth.slice';
import { useDispatch } from 'react-redux';

import { UserService, Usuarios } from '../../../sdk';
import ProdutosService from '../../../sdk/services/Produtos.service';
import useAuth from '../../../core/hooks/useAuth';
import CurrencyInput from '../../components/CurrencyInput';
import { useForm } from 'antd/lib/form/Form';
import CategoriaService from '../../../sdk/services/Categoria.service';

interface FormularioDeProdutosProps {
  produto?: any;
  onSuccess?: () => any;
}

export default function EditarProduto(props: FormularioDeProdutosProps) {
  usePageTitle('Lista de Lojas');
  let { user } = useAuth();
  const access_token = localStorage.getItem('accessToken');
  const [produtoV1, setProdutoV1] = useState<any>();
  const [travado, setTravado] = useState<boolean>(false);
  const [NtipoItem, setNtipoItem] = useState('0');
  const [origem, setOrigem] = useState('0');
  const [produtoFormulario, setProdutoFormualario] = useState<any>(props.produto);
  const [categoriaEscolhida, setCategoriaEscolhida] = useState<any>(
    props.produto.categoria.id
  );
  const initialValues = {
    ...props.produto,
    categoria: categoriaEscolhida,
  };

  const [categorias, setCategorias] = useState<any>([]);
  const [categoriaPronta, setCategoriaPronta] = useState<boolean>(false);
  const [ativo, setAtivo] = useState<boolean>(true);
  const [lojas, setLojas] = useState<Usuarios.comboLojas[]>();
  const refCodigo = useRef(null);

  const [restauranteSelecionado, setRestauranteSelecionado] =
    useState<Usuarios.comboLojas>();
  const [restauranteAtual, setRestauranteAtual] = useState<Usuarios.ListaRestaurantes>();
  const [loading, setLoading] = useState<boolean>(false);
  const [carregarForm, setCarregarForm] = useState<boolean>(true);
  const params = useParams<{ id: string }>();

  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );

  const onSearchCategoria = (value: string) => {};
  const onChangeCategoria = (categoriaAux: any) => {
    setCategoriaEscolhida(categoriaAux);
  };

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [codigoIntegracao, setCodigoIntegracao] = useState<string>();
  const onSearchLoja = (value: string) => {};
  const onChangeLoja = (value: any) => {
    value = JSON.parse(value);

    setRestauranteSelecionado(value as Usuarios.comboLojas);
    UserService.getRestaurantesByID(value.id)
      .then(trataRestauranteAtual)
      .then(setRestauranteAtual);
  };

  const onSearchNtipoItem = (value: string) => {};
  const onSearchOrigem = (value: string) => {};

  let tokenTechsapp = '';

  useEffect(() => {
    if (user?.restaurantes?.id) {
      CategoriaService.getByIdRestaurante(user?.restaurantes?.id as string)
        .then(setCategorias)
        .catch((error) => {
          setCategoriaPronta(true);
          setError(new Error(error.message));
        })
        .finally(() => {
          setCategoriaPronta(true);
        });
    }
  }, [user?.restaurantes]);

  useEffect(() => {
    if (user?.grupos?._embedded.grupos[0].id === 1) {
      setLojas(trataLojasUsuario(user));
    }
    if (user?.grupos?._embedded.grupos[0].id === 5) {
      UserService.getAllRestaurantes()
        .then(trataLojas)
        .then(setLojas)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [tokenTechsapp]);

  useEffect(() => {
    if (codigoIntegracao) {
      if (user?.restaurantes) {
        ProdutosService.getProdutosV1ByCodigoIntegracao(
          codigoIntegracao,
          user?.restaurantes?.dominio,
          user?.restaurantes?.basic_token
        )
          .then((resposta: any) => {
            if (resposta.length > 0) {
              notification.success({
                message: 'Sucesso',
                description: 'Produto sincronizado com sucesso',
              });
            } else {
              notification.error({
                message: 'Não Sincronizado',
                description: 'Produto não encontrado',
              });
            }

            return resposta;
          })
          .then(setProdutoV1)
          .catch((error) => {
            setError(new Error(error.message));
          });
      }
    }
  }, [codigoIntegracao]);

  useEffect(() => {
    if (produtoV1) {
      if (produtoV1.length > 0) {
        if (props.produto) {
          const produtoFormAux = {
            id: props.produto.id,
            nome: produtoV1[0].nome,
            preco: produtoV1[0].valor,
            custo: produtoV1[0].custo,
            descricao: produtoV1[0].descricao,
            codigo: produtoV1[0].codigoIntegracao,
            ncm: props.produto.ncm,
            ean: props.produto.ean,
            cfop: props.produto.cfop,
            cst: props.produto.cst,
            origem: props.produto.origem,
            unidade: props.produto.unidade,
            ntipoItem: props.produto.ntipoItem,
          };
          setNtipoItem(props.produto.ntipoItem);
          setOrigem(props.produto.origem);
          setProdutoFormualario(produtoFormAux);
        } else {
          const produtoFormAux = {
            nome: produtoV1[0].nome,
            preco: produtoV1[0].valor,
            custo: produtoV1[0].custo,
            descricao: produtoV1[0].descricao,
            codigo: produtoV1[0].codigoIntegracao,
            origem: '0',
            ntipoItem: '0',
          };
          setNtipoItem('0');
          setOrigem('0');
          setProdutoFormualario(produtoFormAux);
        }
      }
    } else {
      setNtipoItem('0');
      setOrigem('0');
    }
    setCarregarForm(true);
  }, [produtoV1]);

  function buscaProduto() {}

  function trataRestauranteAtual(
    restaurantes: Usuarios.ListaRestaurantes
  ): Usuarios.ListaRestaurantes {
    if (user && restaurantes) {
      let userAux = {
        grupos: user.grupos,
        usuario: user.usuario,
        restaurantes: restaurantes,
      };
      user = userAux;

      userAux.restaurantes = restaurantes;
      dispatch(updateUser(userAux));
    }
    return restaurantes;
  }
  function trataLojas(
    lojas: Usuarios.ArrayDeRestaurantesDoUsuario
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    lojas._embedded.restaurantes.map((restaurante) => {
      const restauranteAux: Usuarios.comboLojas = {
        id: restaurante.id,
        index: indexCombo,
        nome: restaurante.nome,
        dominio: restaurante.dominio,
        token: restaurante.basic_token,
        cnpj: restaurante.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    });

    return comboRestaurantes;
  }

  function trataLojasUsuario(
    usuario: Usuarios.UsuarioDetalhadoGrupo
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    if (usuario.restaurantes) {
      const restauranteAux: Usuarios.comboLojas = {
        id: usuario.restaurantes.id,
        index: indexCombo,
        nome: usuario.restaurantes.nome,
        dominio: usuario.restaurantes.dominio,
        token: usuario.restaurantes.basic_token,
        cnpj: usuario.restaurantes.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    }

    return comboRestaurantes;
  }

  function trataSwitch() {
    setAtivo(!ativo);
  }

  const [form] = useForm();

  return (
    <>
      {user?.grupos?._embedded.grupos[0].id == 5 ? (
        <Col xs={24} lg={13}>
          <Form.Item label={'Loja'} name={'loja'} style={{ width: '70%' }}>
            <Select
              placeholder={'Selecione o loja'}
              showSearch
              onChange={onChangeLoja}
              onSearch={onSearchLoja}
              optionFilterProp='children'
              defaultValue={lojas ? lojas[0].nome : ''}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {lojas?.map((restaurante) => (
                <Select.Option value={JSON.stringify(restaurante)}>
                  {restaurante.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        ''
      )}
      {carregarForm && categoriaPronta ? (
        <Form
          form={form}
          autoComplete={'off'}
          layout={'horizontal'}
          initialValues={initialValues}
          onFinish={async (formProduto: any) => {
            setLoading(true);

            formProduto.ntipoItem = NtipoItem;
            formProduto.categoria = categoriaEscolhida;

            if (formProduto.ean) {
              if (formProduto.ean.trim() === '') {
                formProduto.ean = 'SEM GTIN';
              }
            } else {
              formProduto.ean = 'SEM GTIN';
            }

            try {
              if (user?.restaurantes?.id) {
                setLoading(true);
                if (props.produto) {
                  let produto = await ProdutosService.atualizarProduto(
                    formProduto,
                    user?.restaurantes?.id,
                    produtoFormulario.id
                  );
                  setLoading(false);

                  if (props.onSuccess) {
                    props.onSuccess();
                  } else {
                    if (produto.id) {
                      notification.success({
                        message: 'Sucesso',
                        description: 'Produto atualizado com sucesso',
                      });
                      history.push(`/produtos`);
                    }
                  }
                }
              }
            } catch (error) {
              setLoading(false);
            }
            setLoading(false);
          }}
        >
          <Row align={'middle'}>
            <legend
              style={{
                borderTop: '1px solid #000',
                margin: '0',
                borderBottom: '0',
                borderLeft: '0',
                borderRight: '0',
                fontWeight: 'bold',
                color: '#000',
              }}
            >
              {'Produtos'}
            </legend>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Nome'}
                name={'nome'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O nome não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input placeholder={'E.x.: xxxxxxxxxxxx'} />
              </Form.Item>
              <Form.Item
                label={'Descrição'}
                name={'descricao'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `A descrição não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input type='text' placeholder={'E.x.: xxxxxxxxxxx'} />
              </Form.Item>

              <Form.Item
                label={'EAN'}
                name={'ean'}
                rules={[
                  {
                    max: 255,
                    message: `O Ean não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input type={'text'} placeholder={'E.x.: 5265'} />
              </Form.Item>
              <Form.Item
                label={'NCM'}
                name={'ncm'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input type={'number'} placeholder={'E.x.: 19059010'} />
              </Form.Item>
              <Form.Item
                label={'CFOP'}
                name={'cfop'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input type={'number'} placeholder={'E.x.:  5102'} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Valor'}
                name={'preco'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <CurrencyInput
                  onChange={(_, value) =>
                    form.setFieldsValue({
                      preco: value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                    label={'Custo'}
                    name={'custo'}
                  
                            >
              <CurrencyInput
                            onChange={(_, value) =>
                               form.setFieldsValue({
                                custo: value,
                               })
                            }
                    />
              </Form.Item>


              <Form.Item
                label={'Unidade'}
                name={'unidade'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `A unidade não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input placeholder={'E.x.: UN'} />
              </Form.Item>

              <Form.Item
                label={'Código de Integração'}
                name={'codigo'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O código não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  ref={refCodigo}
                  style={{
                    width: '95%',
                  }}
                  onBlur={() => {
                    let valorCodigo: any = refCodigo.current;

                    if (
                      valorCodigo.state.value === codigoIntegracao &&
                      codigoIntegracao !== undefined
                    ) {
                      setTravado(true);
                    } else {
                      setTravado(false);
                    }
                  }}
                  placeholder={'E.x.: xxxxxxxxxxxxx'}
                />
              </Form.Item>

              <Form.Item
                label={'NtipoItem'}
                name={'ntipoItem'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder={'Tipo do item'}
                  showSearch
                  onChange={(value) => setNtipoItem(value)}
                  onSearch={onSearchNtipoItem}
                  defaultValue={'0'}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Select.Option value='0'>0 - Produtos</Select.Option>
                  <Select.Option value='1'>1 - Veículos</Select.Option>
                  <Select.Option value='2'>2 - Medicamentos</Select.Option>
                  <Select.Option value='3'>3 - Armamentos</Select.Option>
                  <Select.Option value='4'>4 - Combustível</Select.Option>
                  <Select.Option value='5'>5 - Serviço</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={'Cst'}
                name={'cst'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input type={'number'} placeholder={'E.x.: 102'} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label={'Origem'} name={'origem'}>
                <Select
                  placeholder={'Origem da mercadoria'}
                  showSearch
                  onChange={(value) => setOrigem(value)}
                  onSearch={onSearchOrigem}
                  optionFilterProp='children'
                  defaultValue={'0'}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Select.Option value='0'>
                    0 - Nacional - Exceto as indicadas nos códigos 3,4,5 e 8
                  </Select.Option>
                  <Select.Option value='1'>
                    1 - Estrangeira - Importação Direta
                  </Select.Option>
                  <Select.Option value='2'>
                    2 - Estrangeira - Adq. no mercado interno
                  </Select.Option>
                  <Select.Option value='3'>
                    3 - Nacional - com Conteúdo de Importação superior a 40% e inferior ou
                    igual a 70%
                  </Select.Option>

                  <Select.Option value='4'>
                    4 - Nacional - cuja produção tenha sido feita em conformidade
                  </Select.Option>

                  <Select.Option value='5'>
                    5 - Nacional - com Conteúdo de Importação inferior ou igual a 40%
                  </Select.Option>

                  <Select.Option value='6'>
                    6 - Estrangeira - Imp. direta, sem similar nacional, na lista da CAMEX
                  </Select.Option>

                  <Select.Option value='7'>
                    7 - Estrangeira - Adq. interno, sem similar nacional, na lista da
                    CAMEX
                  </Select.Option>

                  <Select.Option value='8'>
                    8 - Nacional - Mercadoria ou bem com Conteúdo de Importação superior a
                    70%
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Categoria'}
                name={'categoria'}
                style={{ width: '100%' }}
                initialValue={props.produto.categoria.id}
              >
                <Select
                  placeholder={'Selecione a categoria'}
                  showSearch
                  onChange={onChangeCategoria}
                  onSearch={onSearchCategoria}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {categorias?.map((categoria: any) => (
                    <Select.Option key={categoria.id} value={categoria.id}>
                      {categoria.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={24}
              lg={24}
              style={{ display: 'flex', justifyContent: 'end', gap: '30px' }}
            >
              <Button
                type={'primary'}
                htmlType={'button'}
                disabled={travado}
                onClick={() => {
                  const valorCodigo: any = refCodigo.current;
                  if (valorCodigo.state.value) {
                    if (user?.restaurantes) {
                      setCodigoIntegracao('');
                      setCodigoIntegracao(valorCodigo.state.value);
                      setCarregarForm(false);
                      setTravado(true);
                    }
                  }
                }}
              >
                Sincronizar v1
              </Button>
              <Form.Item label='Status' name={'ativo'} valuePropName='checked'>
                <Switch
                  onChange={trataSwitch}
                  defaultChecked
                  checkedChildren={'Ativo'}
                  unCheckedChildren={'Inativo'}
                  checked={ativo}
                />
              </Form.Item>
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                  Salvar Produto
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  style={{
                    backgroundColor: props.produto ? 'red' : '#E5E5E5',
                    borderColor: props.produto ? 'red' : '#E5E5E5',
                  }}
                  disabled={props.produto ? false : true}
                  onClick={async () => {
                    const respExclusão = await ProdutosService.excluirProdutoByID(
                      user?.restaurantes?.id as string,
                      props.produto.id
                    );

                    notification.success({
                      message: 'Sucesso',
                      description: 'Produto excluido com sucesso',
                    });
                    history.push(`/produtos`);
                  }}
                >
                  Excluir Produto
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Skeleton></Skeleton>
      )}
    </>
  );
}
